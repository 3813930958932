<template>
  <div class="saas">
    <div class="container">
      <div class="title">全流程数智赋能</div>
      <div class="sub-title">强大的农产品贸易商SaaS系统</div>
      <div class="box">
        <div class="left">
          <div class="left-section">
            <div class="section-title-box">
              <div class="section-title">持续迭代，快速更新</div>
              <div class="section-title">年度更新1600+项功能</div>
              <img class="left-icon" src="../../assets/img/saas1.png" alt="">
            </div>
            <div class="text-row">商品管理，订单管理，采购管理</div>
            <div class="text-row">食品溯源，分拣管理，财务管理</div>
            <div class="text-row">门店管理，绩效管理，数据统计 ...</div>
          </div>
          <div class="left-section">
            <div class="section-title-box">
              <div class="section-title">全链路降本增效</div>
              <div class="section-title">3~5%净利润提升</div>
              <img class="left-icon" src="../../assets/img/saas1.png" alt="">
            </div>
            <div class="text-row">降低损耗，节省成本</div>
            <div class="text-row">科学管理企业及员工</div>
            <div class="text-row">数智化系统推动企业发展转型</div>
          </div>
        </div>
        <div class="center">
          <div class="center-text">产品优</div>
          <div class="center-text">服务好</div>
          <div class="center-text">效果显</div>
          <div class="center-text">成本低</div>
        </div>
        <div class="right">
          <div class="right-section">
            <div class="section-title-box">
              <div class="section-title">快速响应</div>
              <div class="section-title">秒级预警检测</div>
              <img class="right-icon" src="../../assets/img/saas2.png" alt="">
            </div>
            <div class="text-row">8大服务中心，覆盖200+城市</div>
            <div class="text-row">生鲜研究院，赋能1万+企业提效20%</div>
            <div class="text-row">秒级预警检测，为您保驾护航</div>
          </div>
          <div class="right-section">
            <div class="section-title-box">
              <div class="section-title">生鲜业财一体化</div>
              <div class="section-title">生鲜配送系统</div>
              <img class="right-icon" src="../../assets/img/saas2.png" alt="">
            </div>
            <div class="text-row">一套系统打通生鲜供应链全流程</div>
            <div class="text-row">商品信息流、物流、资金流，三位一体</div>
            <div class="text-row">打造一体化智能平台信息管理</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "SaasIntroduce1",
  data() {
    return {
      
    }
  },
}
</script>

<style lang='less' scoped>
.saas {
  width: 100%;
  margin-bottom: 150px;
  .container {
    width: 1220px;
    height: 100%;
    margin: 0 auto;
    .title {
      font-size: 30px;
      color: #313131;
      font-weight: bolder;
      text-align: center;
      position: relative;
      margin-top: 130px;
    }
    .sub-title {
      font-size: 16px;
      color: #313131;
      text-align: center;
      margin-bottom: 60px;
    }
    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 324px;
        .left-section {
          &:first-child {
            margin-bottom: 30px;
          }
          .section-title-box {
            position: relative;
            margin-bottom: 30px;
            .section-title {
              font-size: 22px;
              color: #0074ff;
            }
            .left-icon {
              position: absolute;
              bottom: 0px;
              right: 0px
            }
            &::before {
              content: "";
              display: inline-block;
              width: 100%;
              height: 2px;
              background-color: #bfbfbf;
              position: absolute;
              bottom: -15px;
            }
            &::after {
              content: "";
              display: inline-block;
              width: 100px;
              height: 4px;
              background-color: #0074ff;
              position: absolute;
              bottom: -16px;
            }
          }
          .text-row {
            font-size: 16px;
            color: #313131;
          }
        }
      }
      .center {
        width: 460px;
        height: 299px;
        background-image: url('../../assets/img/saas-bg.png');
        background-size: 100% 100%;
        display: flex;
        flex-wrap: wrap;
        .center-text {
          width: 50%;
          height: 50%;
          font-size: 30px;
          color: #fff;
          text-align: center;
          line-height: 140px;
        }
      }
      .right {
        width: 324px;
        .right-section {
          &:first-child {
            margin-bottom: 30px;
          }
          .section-title-box {
            position: relative;
            margin-bottom: 30px;
            .section-title {
              font-size: 22px;
              color: #0074ff;
              text-align: end;
            }
            .right-icon {
              position: absolute;
              bottom: 0px;
              left: 0px
            }
            &::before {
              content: "";
              display: inline-block;
              width: 100%;
              height: 2px;
              background-color: #bfbfbf;
              position: absolute;
              bottom: -15px;
            }
            &::after {
              content: "";
              display: inline-block;
              width: 100px;
              height: 4px;
              background-color: #0074ff;
              position: absolute;
              bottom: -16px;
              right: 0;
            }
          }
          .text-row {
            font-size: 16px;
            color: #313131;
            text-align: end;
          }
        }
      }
    }
  }
}
</style>
