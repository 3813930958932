<template>
  <div class="home">
    <banner></banner>

    <core></core>

    <product></product>

    <saas-introduce1></saas-introduce1>

    <saas-introduce2></saas-introduce2>

    <order-introduce></order-introduce>

    <order-waters></order-waters>

    <application-scenario></application-scenario>
  </div>
</template>

<script type="text/javascript">
import Banner from '../../components/homecoms/Banner.vue';
import Core from '../../components/homecoms/Core.vue';
import Product from '../../components/homecoms/Product.vue';
import ApplicationScenario from '../../components/homecoms/ApplicationScenario.vue';
import OrderIntroduce from '../../components/homecoms/OrderIntroduce.vue';
import OrderWaters from '../../components/homecoms/OrderWaters.vue';
import SaasIntroduce1 from '../../components/homecoms/SaasIntroduce1.vue';
import SaasIntroduce2 from '../../components/homecoms/SaasIntroduce2.vue';

export default {
  name: "HeaderContainer",
  data() {
    return {
      
    }
  },
  components: {
    Banner,
    Core,
    Product,
    ApplicationScenario,
    OrderIntroduce,
    OrderWaters,
    SaasIntroduce1,
    SaasIntroduce2,
  }
}
</script>

<style lang='less' scoped>
.home {
  width: 100%;
}
</style>
