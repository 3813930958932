<template>
  <div>
    <div class="core">
      <div class="title">核心功能</div>
      <div class="list">
        <div class="item">
          <div class="icon"><img src="../../assets/img/core1.png" alt=""></div>
          <div class="item-title">采购</div>
          <div class="text-row">计划采购/采购开单</div>
          <div class="text-row">采购历史/退供管理</div>
          <div class="text-row">...</div>
        </div>
        <div class="item">
          <div class="icon"><img src="../../assets/img/core2.png" alt=""></div>
          <div class="item-title">销售</div>
          <div class="text-row">销售开单/销售历史</div>
          <div class="text-row">退货管理/零售业务</div>
          <div class="text-row">...</div>
        </div>
        <div class="item">
          <div class="icon"><img src="../../assets/img/core3.png" alt=""></div>
          <div class="item-title">仓储</div>
          <div class="text-row">仓库管理/库存查询</div>
          <div class="text-row">库存盘点/出入库管理</div>
          <div class="text-row">...</div>
        </div>
        <div class="item">
          <div class="icon"><img src="../../assets/img/core4.png" alt=""></div>
          <div class="item-title">物流</div>
          <div class="text-row">物流开单/物流查询</div>
          <div class="text-row">流转记录/车辆管理</div>
          <div class="text-row">...</div>
        </div>
        <div class="item">
          <div class="icon"><img src="../../assets/img/core5.png" alt=""></div>
          <div class="item-title">对账</div>
          <div class="text-row">供应商对账/客户对账</div>
          <div class="text-row">差额管理</div>
          <div class="text-row">...</div>
        </div>
        <div class="item">
          <div class="icon"><img src="../../assets/img/core6.png" alt=""></div>
          <div class="item-title">财务</div>
          <div class="text-row">会计科目管理/账户管理</div>
          <div class="text-row">财务收付款/日常费用管理</div>
          <div class="text-row">...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "Core",
  data() {
    return {
      
    }
  },
}
</script>

<style lang='less' scoped>
.core {
  width: 1220px;
  margin: 0 auto;
  .title {
    font-size: 36px;
    color: #0074ff;
    font-weight: bolder;
    text-align: center;
    position: relative;
    margin-top: 110px;
    margin-bottom: 70px;
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #0074ff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 40%;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #0074ff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 40%;
    }
  }
  .list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        width: 120px;
        height: 100px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-title {
        color: #323232;
        font-size: 30px;
        text-align: center;
        margin-bottom: 20px;
      }
      .text-row {
        color: #323232;
        font-size: 16px;
        text-align: center;
      }
    }
  }
}
</style>
