<template>
  <div class="supply">
    <div class="container">
      <div class="title-box">
        <div class="title">全供应链管理</div>
        <div class="sub-title">能够满足管理、运营和操作各层级使用者的高用户体验</div>
      </div>
      <div class="box">
        <div class="left">
          <div class="left-section">
            <img class="icon" src="../../assets/img/supply1.png" alt="">
            <div class="text-row">标准化商贸管理流程，降低</div>
            <div class="text-row">沟通成本，增加办公效率。</div>
          </div>
          <div class="left-section">
            <img class="icon" src="../../assets/img/supply2.png" alt="">
            <div class="text-row">解决多系统业务孤岛问题，</div>
            <div class="text-row">贯穿线上、线下全场景业务。</div>
          </div>
        </div>
        <div class="center">
          <div class="text-row">直击</div>
          <div class="text-row">行业痛点</div>
        </div>
        <div class="right">
          <div class="right-section">
            <img class="icon" src="../../assets/img/supply3.png" alt="">
            <div class="text-row">解决业务对账、</div>
            <div class="text-row">财务合并业务单。</div>
          </div>
          <div class="right-section">
            <img class="icon" src="../../assets/img/supply4.png" alt="">
            <div class="text-row">多维度数据统计、让决策</div>
            <div class="text-row">者更清晰企业业务流状况。</div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "SaasIntroduce2",
  data() {
    return {
      
    }
  },
}
</script>

<style lang='less' scoped>
.supply {
  min-width: 1220px;
  width: 100%;
  height: 600px;
  background-color: #f7f7f7;
  .container {
    width: 1220px;
    margin: 0 auto;
    padding-top: 80px;
    .title-box {
      .title {
        font-size: 36px;
        color: #333;
        text-align: center;
      }
      .sub-title {
        font-size: 24px;
        color: #666;
        text-align: center;
      }
    }
    .box {
      margin-top: 40px;
      display: flex;
      height: 300px;
      .left {
        width: 460px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .left-section{
          position: relative;
          padding-left: 45px;
          width: 280px;
          &:first-child {
            margin-bottom: 40px;
          }
          .icon {
            position: absolute;
            left: 0;
            top: 0;
          }
          .text-row {
            font-size: 18px;
            color: #313131;
          }
        }
      }
      .center {
        width: 300px;
        height: 300px;
        background-image: url('../../assets/img/supply-bg.png');
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text-row {
          font-size: 20px;
          color: #fff;
        }
      }
      .right {
        width: 460px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .right-section{
          position: relative;
          padding-left: 45px;
          width: 280px;
          &:first-child {
            margin-bottom: 40px;
          }
          .icon {
            position: absolute;
            left: 0;
            top: 0;
          }
          .text-row {
            font-size: 18px;
            color: #313131;
          }
        }
      }
    }
  }
}
</style>
