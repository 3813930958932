<template>
  <div class="order">
    <div class="container">
      <div class="text-box">
        <img class="icon" src="../../assets/img/order-icon.png" alt="">
        <div class="title">多单据同时处理</div>
        <div class="text-area">一个物流单可以同时包含多种单据 （如：多个销售单、退货单、回收单） 一趟车完成多个业务场景。</div>
      </div>
      <img class="order-img" src="../../assets/img/order-img.png" alt="">
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "OrderIntroduce",
  data() {
    return {
      
    }
  },
}
</script>

<style lang='less' scoped>
.order {
  width: 100%;
  height: 570px;
  background-image: url('../../assets/img/order-bg.png');
  background-size: 100% 100%;
  .container {
    width: 1220px;
    margin: 0 auto;
    position: relative;
    .text-box {
      position: absolute;
      top: 220px;
      left: 160px;
      width: 460px;
      padding-left: 50px;
      .icon {
        position: absolute;
        left: 0;
        top: 14px;
      }
      .title {
        font-size: 36px;
        color: #000;
      }
      .text-area {
        font-size: 24px;
        color: #000;
        line-height: 36px;
      }
    }
    .order-img {
      position: absolute;
      top: 25px;
      left: 650px;
    }
  }
}
</style>
