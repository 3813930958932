<template>
  <div class="app-container">
    <div class="swiper">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item,index) in bannerList" :key="index" :style="{backgroundImage: 'url('+item.img+')'}"></swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script type="text/javascript">
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: "Banner",
  data() {
    return {
      bannerList: [{
        img: require('../../assets/img/banner1.png')
      },{
        img: require('../../assets/img/banner2.png')
      },{
        img: require('../../assets/img/banner3.png')
      }],
      swiperOptions: {
        loop: true,
        speed: 600,
        autoplay: {
          delay: 3000, //3秒切换一次
          pauseOnMouseEnter: true, //悬停停止切换
        },
      }
    }
  },
  components: {
    swiper,
    swiperSlide
  }
}
</script>

<style lang='less' scoped>
.app-container{
  min-width: 1220px;
  width: 100%;
  height: 600px;
  .swiper{
    min-width: 1220px;
    width: 100%;
    height: 100%;
    .swiper-slide {
      min-width: 1220px;
      width: 100%;
      height: 600px;
      background-size: 100% 100%;
    }
  }
}
</style>
