<template>
  <div class="product">
    <div class="container">
      <div class="title">产品介绍</div>
      <div class="sub-title">基于云服务的新一代技术架构</div>
      <div class="list">
        <div class="item">
          <div class="item-title">进销存管理</div>
          <div class="text-row">产品管理、采购管理、</div>
          <div class="text-row">加工管理、销售管理、</div>
          <div class="text-row">仓储管理、物流管理...</div>
          <div class="check-detail">查看详情<img src="../../assets/img/arrow.png" alt=""></div>
          <img class="product-icon" src="../../assets/img/product1.png" alt="">
        </div>
        <div class="item">
          <div class="item-title">服务管理</div>
          <div class="text-row">合同管理、人事管理、</div>
          <div class="text-row">资产管理、对账管理、</div>
          <div class="text-row">财务管理、统计管理...</div>
          <div class="check-detail">查看详情<img src="../../assets/img/arrow.png" alt=""></div>
          <img class="product-icon" src="../../assets/img/product2.png" alt="">
        </div>
        <div class="item">
          <div class="item-title">电商管理</div>
          <div class="text-row">商铺管理、会员管理、</div>
          <div class="text-row">...</div>
          <div class="check-detail">查看详情<img src="../../assets/img/arrow.png" alt=""></div>
          <img class="product-icon" src="../../assets/img/product3.png" alt="">
        </div>
        <div class="item">
          <div class="item-title">财务管理</div>
          <div class="text-row">会计科目管理、</div>
          <div class="text-row">账户管理、财务收付款、</div>
          <div class="text-row">日常费用管理...</div>
          <div class="check-detail">查看详情<img src="../../assets/img/arrow.png" alt=""></div>
          <img class="product-icon" src="../../assets/img/product4.png" alt="">
        </div>
        <div class="item">
          <div class="item-title">统计管理</div>
          <div class="text-row">分析管理、会员管理</div>
          <div class="text-row">...</div>
          <div class="check-detail">查看详情<img src="../../assets/img/arrow.png" alt=""></div>
          <img class="product-icon" src="../../assets/img/product5.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "Product",
  data() {
    return {
      
    }
  },
}
</script>

<style lang='less' scoped>
.product {
  min-width: 1220px;
  width: 100%;
  height: 700px;
  background-image: url('../../assets/img/product-bg.png');
  background-size: 100% 100%;
  .container {
    width: 1220px;
    height: 100%;
    margin: 0 auto;
    padding-top: 110px;
    .title {
      font-size: 36px;
      color: #fff;
      font-weight: bolder;
      text-align: center;
      position: relative;
      margin-bottom: 50px;
      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40%;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 40%;
      }
    }
    .sub-title {
      font-size: 30px;
      color: #fff;
      text-align: center;
      margin-bottom: 60px;
    }
    .list {
      display: flex;
      justify-content: space-between;
      .item {
        width: 220px;
        height: 400px;
        background-color: #eef7ff;
        border-radius: 6px;
        padding: 35px 20px 0 20px;
        position: relative;
        .item-title {
          font-size: 26px;
          color: #313131;
          margin-bottom: 20px;
        }
        .text-row {
          font-size: 16px;
          color: #313131;
          margin-bottom: 20px;
        }
        .check-detail {
          cursor: pointer;
          position: absolute;
          top: 230px;
          left: 20px;
          font-size: 16px;
          color: #0074ff;
          img {
            vertical-align: bottom;
            margin-left: 20px;
          }
        }
        .product-icon {
          position: absolute;
          top: 274px;
          left: 70px;
        }
      }
    }
  }
}
</style>
