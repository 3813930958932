<template>
  <div class="application">
    <div class="container">
      <div class="title">应用场景</div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "ApplicationScenario",
  data() {
    return {
      
    }
  },
}
</script>

<style lang='less' scoped>
.application {
  width: 100%;
  height: 1000px;
  background-image: url('../../assets/img/application-bg.png');
  background-size: contain;
  .container {
    width: 1220px;
    height: 1000px;
    margin: 0 auto;
    position: relative;
    background-image: url('../../assets/img/application.png');
    background-size: 100% 100%;
    .title {
      font-size: 36px;
      color: #333;
      text-align: center;
      padding-top: 88px;
    }
  }
}
</style>
