<template>
  <div class="document">
    <div class="container">
      <div class="text-box">
        <div class="title">单据流转</div>
        <div class="text-area">只需要创建采购单，在不同的阶段自动创建 入库单、物流单、业务单、财务单。</div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "OrderWaters",
  data() {
    return {
      
    }
  },
}
</script>

<style lang='less' scoped>
.document {
  background-color: #f7f7f7;
  min-width: 1220px;
  width: 100%;
  height: 600px;
  .container {
    width: 1220px;
    height: 600px;
    margin: 0 auto;
    position: relative;
    background-image: url('../../assets/img/document-bg.png');
    background-size: 100% 100%;
    .text-box {
      position: absolute;
      top: 388px;
      left: 565px;
      width: 510px;
      padding-left: 50px;
      .title {
        font-size: 36px;
        color: #000;
        margin-bottom: 20px;
      }
      .text-area {
        font-size: 24px;
        color: #000;
        line-height: 36px;
      }
    }
  }
}
</style>
